import React, { createContext, useReducer } from 'react'
import { merge } from "lodash"

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const initialState = {
  companySessionMap: {},
  currentUser: {},
}

function reducer(state, action) {
  switch(action.type) {
    case 'UPDATE_COMPANY_SESSION_MAP':
      return {
        ...state,
        companySessionMap: merge(state.companySessionMap, action.payload),
      };
    case 'UPDATE_USER':
      break;
    default:
      throw new Error("Bad action type");
  }  
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider